$(function(){
	if($('#redeem-type').length > 0) {
		$('#redeem-type select').change(function(){
			if($(this).val() == 1) {
				$('#wagger-point').removeClass('hidden');
			}else {
				$('#wagger-point').addClass('hidden');
				$('#wagger-point input[type="text"]').val('');
			}
		});
	}
	
	if ($('#reward-type').length > 0) {
		$('#reward-type select').change(function(){
			if ($(this).val() == 1) {
				$('#reward-rules-btn').removeClass('hidden');
			}
			else {
				$('#reward-rules-btn').addClass('hidden');
			}
		});
	}
	
	if ($('#reward-rules-modal').length > 0) {
		$('#reward-rules-modal-content').on('click','.pagination .page-link',function(event) {
			event.preventDefault();
			console.log(event);
			
			$('#reward-rules-modal .spinner').removeClass('hidden');
			var url = $(this).attr('href');
			$.get(url,function(data) {
				$('#reward-rules-modal .spinner').addClass('hidden');
				$('#reward-rules-modal-content').empty().append(data);
			});
		});
		$('#reward-rules-modal').on('shown.bs.modal',function (event) {
			var $this = $(this);
			var url = $(event.relatedTarget).data('url');
			$this.find('.spinner').removeClass('hidden');
			$.get(url,function (data) {
				$this.find('.spinner').addClass('hidden');
				$('#reward-rules-modal-content').append(data);
			});
		}).on('hidden.bs.modal',function(event) {
			$('#reward-rules-modal-content').empty();
		});
	}
});