$(function() {    
    $(document).on('click', '.delete', function(event) {    
        event.preventDefault();        
        var params = {
            type: "delete",
            title: "Please Confirm !!!",
            body: "Do you want to delete this item ?"
        };        
        modal_confirm(params);
        open_modal_confirm();                
        url = $(this).attr("href");
        $("#modal-confirm .btn-confirm").click(function() {                        
            $.ajax({
                type: "DELETE",
                url: url,
                data: { '_token': $('input[name=_token]').val(), },
                success: function(res) {                    
                    if(res.result) {    
                        if(typeof res.reload != 'undefined' && res.reload == false) {
                            $(res.target).html(res.content);
                        }else {
                            window.location.reload();    
                        }                        
                    }
                    close_modal_confirm();
                },                
            });
        });
    });

    $(document).on('click', '.remove', function(event) {    
        event.preventDefault();        
        var params = {
            type: "delete",
            title: "Please Confirm !!!",
            body: "Do you want to remove"
        };        
        params.body += $(this).data('message') + "?";
        modal_confirm(params);
        open_modal_confirm();                
        url = $(this).attr("href");
        $("#modal-confirm .btn-confirm").click(function() {                        
            $.ajax({
                type: "DELETE",
                url: url,
                data: { '_token': $('input[name=_token]').val(), },
                success: function(res) {                    
                    if(res.result) {    
                        if(typeof res.reload != 'undefined' && res.reload == false) {
                            $(res.target).html(res.content);
                        }else {
                            window.location.reload();    
                        }                        
                    }
                    close_modal_confirm();
                },                
            });
        });
    });

    $(document).on('click', '.confirm-remove', function(event) {    
        event.preventDefault();        
        var params = {
            type: "confirm-remove",
            title: "Please Confirm !!!",
            body: "Do you want to "
        };
        params.body += $(this).data('message') + "?";
        modal_confirm(params);
        open_modal_confirm();                
        url = $(this).attr("href");
        $("#modal-confirm .btn-confirm").click(function() {                        
            $.ajax({
                type: "post",
                url: url,
                data: { '_token': $('input[name=_token]').val(), },
                success: function(res) {                    
                    if(res.result) {    
                        if(typeof res.reload != 'undefined' && res.reload == false) {
                            $(res.target).html(res.content);
                        }else {
                            window.location.reload();    
                        }                        
                    }
                    close_modal_confirm();
                },                
            });
        });
    });

    $(document).on('click', '.confirm', function(event) {    
        event.preventDefault();        
        var params = {
            type: "confirm",
            title: "Please Confirm !!!",
            body: "Do you want to "
        };
        params.body += $(this).data('message') + "?";

        modal_confirm(params);
        open_modal_confirm();                
        url = $(this).attr("href");
        $("#modal-confirm .btn-confirm").click(function() {                        
            $.ajax({
                type: "post",
                url: url,
                data: { '_token': $('input[name=_token]').val(), },
                success: function(res) {                    
                    if(res.result) {    
                        if(typeof res.reload != 'undefined' && res.reload == false) {
                            $(res.target).html(res.content);
                        }else {
                            window.location.reload();    
                        }                        
                    }
                    close_modal_confirm();
                },                
            });
        });
    });

    bind_select2 = function(){
        //$(".select2").select2("destroy");
        //$(".select2-no-search").select2("destroy");

        $("select.select2:not(.select2-hidden-accessible)").select2();
        $("select.select2-no-search:not(.select2-hidden-accessible)").select2({
            minimumResultsForSearch: Infinity
        });
    };
    bind_select2();

    $('.file-upload').change(function(){
        if($(this).hasClass('thumbnail')){
            $("#upload-file-info-thumbnail").html($(this).val());
            $('.sel-file-info-thumbnailw').removeClass('hidden');
            
        }else {
            $("#upload-file-info").html($(this).val());
            $('.sel-file-info').removeClass('hidden');    
        }        
    });

    $('.file-upload-multiple').change(function() {        
        $('.upload-file-info.multiple:not(.hidden)').remove();
        $.each($(this).get(0).files, function(index, file){
            console.log(file);
            //console.log(file);
            //$('.upload-file-info.template').clone().html(file.name).appendTo('.sel-file-info.multiple');
            $('.upload-file-info.multiple.hidden').clone().html(file.name).removeClass('hidden').appendTo('.sel-file-info.multiple');
            //console.log($item);
        });
        
        //$('.sel-file-info').removeClass('hidden');
    });

    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy'
    });

    $(document).on('submit', '.ajax-form', function(event) {
        event.preventDefault();
        //Submit Form
        $form = $(this);
        $.ajax({
            type: "POST",
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function(res) {
                if(res.result) {                        
                    $('input[type="text"]', $form).val('');
                    if(typeof res.reload != 'undefined' && res.reload == false) {
                        $(res.target).html(res.content);                        
                    } else {                        
                        window.location.reload();
                    }
                    if(typeof res.reload == 'undefined' || res.close_modal == true) {
                        $('.modal').modal('hide');
                    }
                }else {

                }
                
            },                
        });
    });

    $(document).on('click', '.ajax-modal', function(event) {
        event.preventDefault();
        init_modal();
        $this = $(this);
        url = $this.attr('href');
        $.ajax({
            type: "GET",
            url: url,            
            success: function(res) {
                if(res.result) {                                            
                    $('#modal-admin .modal-content').html(res.content);
                    open_modal();
                }else {

                }
                
            },                
        });

    });

    $(document).on('change', '.ajax-change', function(event) {
        event.preventDefault();        
        $this = $(this);
        url = $this.data('url') + '/' + $this.val();
        $.ajax({
            type: "GET",
            url: url,            
            success: function(res) {
                if(res.result) {                                            
                    if(res.reload)
                        window.location.reload();
                    else {
                        
                        if(res.target instanceof Array)
                            for (var i = 0; i < res.target.length; i++) {
                                target = res.target[i];
                                content = res.content[i];
                                $(target).html(content);
                                bind_select2();
                            }
                        else {
                            $(res.target).html(res.content);
                        }
                        
                    }
                }else {

                }                
            },                
        });

    });


});