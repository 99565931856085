$(function() {    
    $.fn.modal.Constructor.DEFAULTS.backdrop = 'static';
    modal_confirm = function(params) {
        if (!$("#modal-confirm").length > 0) {            
            var $template = "";
            $template += "<div id='modal-confirm' class='modal' tabindex='-1' role='dialog' aria-labelledby='confirm-modal' aria-hidden='true'>";
            $template += "  <div class='modal-dialog' role='document'>";
            $template += "      <div class='modal-content'>";
            $template += "          <div class='modal-header'>";
            $template += "              <button type='button' class='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button> ";                
            $template += "              <h4 class='modal-title'></h4>";
            $template += "          </div>";
            $template += "          <div class='modal-body'>";                
            $template += "          </div>";
            $template += "          <div class='modal-footer'>";
            $template += "              <button type='button' class='btn btn-default' data-dismiss='modal'>No</button>";
            $template += "              <button type='button' class='btn btn-outline btn-confirm'>Yes</button>";            
            $template += "          </div>";
            $template += "      </div>";
            $template += "  </div>";
            $template += "</div>";                
            $("body").append($template);
            $('#modal-confirm').modal({
                backdrop: 'static',
                keyboard: false
            });     
            $('#modal-confirm').on('hidden.bs.modal', function(e) {                    
                $(".btn-confirm").unbind("click");
            });            
        }
        $header = $('#modal-confirm.modal');        
        if(params.type == "delete" || params.type == "confirm-remove") {            
            if(!$header.hasClass('modal-danger')) {                
                $header.removeClass().addClass('modal modal-danger');                
            }        
        }else if(params.type == "confirm") {            
            if(!$header.hasClass('modal-primary')) {                
                $header.removeClass().addClass('modal modal-primary');                
            }
        }
        $('#modal-confirm .modal-title').html(params.title);
        $('#modal-confirm .modal-body').html(params.body);
    }
    open_modal_confirm = function () {
        if($('#modal-confirm').length > 0) {
           $('#modal-confirm').modal('show')
        }
    }
    close_modal_confirm = function () {    
        $('#modal-confirm').modal('hide');
    }

    init_modal = function(params) {
        if (!$("#modal-confirm").length > 0) {            
            var $template = "";
            $template += "<div class='modal fade' id='modal-admin'>";
            $template += "  <div class='modal-dialog'>";
            $template += "      <div class='modal-content'>";                        
            $template += "      </div>";
            $template += "  </div>";
            $template += "</div>";

            $("body").append($template);
            $('#modal-admin').modal();                 
        }        
    }
    open_modal = function () {
        if($('#modal-admin').length > 0) {
           $('#modal-admin').modal('show')
        }
    }
    close_modal = function () {    
        $('#modal-admin').modal('hide');
    }

});